<template>
    <div ref="invoicePdf" class="invoice-wrapper">
        <div class="invoice-content dark-text">
            <button class="invoice-back text-button no-bg pointer" @click="$router.back()">
                <span class="material-icons-outlined">arrow_back</span> Back</button>
            <h2 class="align-center bottom-20">INVOICE</h2>
            <hr class="gray top-0 bottom-20" />
            <div class="flex space-between bottom-20">
                <div v-if="company">
                    <p>{{ company.name }}</p>
                    <p>{{ companyAddress.street }}</p>
                    <p>{{ companyAddress.city }}, {{ companyAddress.state }} {{ companyAddress.zip }}</p>
                </div>
                <DynamicImage
                    alt="logo"
                    :urlToRetrieveFile="company.logo ? file.getFile(company.logo) : null"
                    class="company-logo"
                />
            </div>
            <div v-if="client" class="flex space-between bottom-20">
                <div class="">
                    <p>Invoice To: {{ client.name }}</p>
                    <p>{{ clientAddress.street }}</p>
                    <p>{{ clientAddress.city }}, {{ clientAddress.state }} {{ clientAddress.zip }}</p>
                    <p>{{ client.phone_number }}</p>
                    <p>{{ client.email }}</p>
                </div>
                <div class="invoice-to right-10">
                    <p>
                        <span class="label">Issue Date:</span>
                        <span>{{ getShortDate(data.issued_date) }}</span>
                    </p>
                    <p>
                        <span class="label">Invoice #:</span>
                        <span>{{ data.invoice_number }}</span>
                    </p>
                    <p>
                        <span class="label">Provider Name:</span>
                        <span>{{ provider.first_name }} {{ provider.last_name
                            }}{{ Boolean(provider.licenses) ? `, ${provider.licenses}` : null }}</span>
                    </p>
                    <p>
                        <span class="label">Provider Email:</span>
                        <span>{{ provider.email }}</span>
                    </p>
                    <p>
                        <span class="label">NPI #:</span>
                        <span>{{ provider.npi }}</span>
                    </p>
                </div>
            </div>
            <hr class="gray top-0 bottom-20" />
            <div v-if="client" class="bottom-20">
                <p>Client Name: {{ client.name }}</p>
                <p>{{ clientAddress.street }}</p>
                <p>{{ clientAddress.city }}, {{ clientAddress.state }} {{ clientAddress.zip }}</p>
                <p>{{ client.phone_number }}</p>
                <p>{{ client.email }}</p>
            </div>

            <table class="invoice-table bottom-20">
                <thead class="border-top">
                    <tr>
                        <th>Date</th>
                        <th>Description</th>
                        <th>Service Fee</th>
                        <th>Client Responsibility</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(service, i) in serviceItems" :key="service.id">
                        <td>{{ getShortDate(service.date) }}</td>
                        <td class="service">
                            <div>{{ service.code }} - {{ service.description }}</div>
                        </td>
                        <td>{{ $getCurrency(service.retail_cost) }}</td>
                        <td>
                            <CurrencyInput
                                @currency="handleCurrencyChanged($event, service, i)"
                                :initialValue="service.actual_cost"
                            />
<!--                            <button v-if="isDirty(i)" @click="adjustServiceFee(service)">Save</button>-->
                            <button v-if='isDirty(i)' @click='openReasonModal(service)'>Save</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="bottom-40 align-right">
                <div>
                    <p><strong>Amount Paid:</strong> {{ $getCurrency(data.amount_paid) }}</p>
                </div>
              <div v-if="data.amount_credited">
                <p><strong>Amount Credited:</strong> {{ $getCurrency(data.amount_credited) }}</p>
              </div>
                <div>
                    <p><strong>Amount Due:</strong> {{ $getCurrency(data.amount_owed) }}</p>
                </div>
            </div>
            <div class="bottom-20">
                <button
                    class="primary invoice-pg-pay-btn"
                    :class="{ hidden: savingPdf }"
                    @click="paymentModalOpen = true"
                    :disabled="invoicePaid"
                >
                    {{ invoicePaid ? 'Invoice Paid' : 'Pay Invoice' }}
                </button>
                <button class="secondary do-not-print-me" @click="getPdf">View as PDF</button>
                <button class="secondary do-not-print-me" :title="saveTitle" @click="savePdf">Save as PDF</button>
                <button v-if="data.progress_note_id && financialClass && !noShowOrCancelled" class="secondary do-not-print-me" @click.prevent="invoice2claim">Insurance Claim</button>
                <button v-if="data.progress_note_id" class="secondary do-not-print-me" type="button" @click.prevent="goto_progress_note">Progress Note</button>
                <button v-if="clientId" class="secondary do-not-print-me" type="button" @click.prevent="go2client">View Client</button>
            </div>
            <PayInvoiceModal
                v-if="paymentModalOpen"
                :clientId="clientId"
                :balance="balance"
                :invoiceId="invoice_id"
                @close="handlePaymentModalClose"
            />
            <ReasonModal
                :open='showReasonModal'
                @close='handleReasonModalClose'
                :payload='currentServiceToAdjust'
                description='Please provide a reason for adjusting the amount.'
            >

            </ReasonModal>
        </div>
    </div>
</template>

<script>
    import { invoices, serviceItems } from '@/util/apiRequests';
    import dayjs from '@/util/dayjs';
    import html2pdf from 'html2pdf.js';
    import CurrencyInput from '@/components/general/inputs/CurrencyInput';
    import PayInvoiceModal from '@/components/invoice/PayInvoiceModal';
    import ReasonModal from '@/components/general/modals/ReasonModal';
    import DynamicImage from '@/components/general/image/Image';
    import { file } from '@/util/apiRequests';
    import { openPdf } from '@/util/pdf';

    export default {
        name: 'Invoice',
        components: { DynamicImage, PayInvoiceModal, CurrencyInput, ReasonModal },
        props: {
            invoice_id: {
                type: [Number, String],
                required: true
            }
        },
        data() {
            return {
                data: {},
                paymentModalOpen: false,
                dirtyIndexes: [],
                savingPdf: false,
                file,
                clientFinancialClass: null,
                currentServiceToAdjust: null,
                showReasonModal: false
            };
        },
        computed: {
            company() {
                return this.data.company ?? {};
            },
            companyAddress() {
                return this.data.company?.address ?? {};
            },
            client() {
                return this.data.client ?? {};
            },
            clientAddress() {
                return this.data.client?.address ?? {};
            },
            clientId() {
                return this.data.client?.id ?? 0;
            },
            provider() {
                return this.data.provider ?? {};
            },
            serviceItems() {
                return this.data.service_items ?? [];
            },
            invoicePaid() {
                return this.data.amount_owed <= 0;
            },
            balance() {
                return this.data.amount_owed ?? 0;
            },
            saveTitle() {
                if (this.data.client) {
                    return `Save Invoice to Files for ${this.data.client.name} as PDF`;
                } else {
                    return null;
                }
            },
            financialClass() {
                if (this.clientFinancialClass == 'commercial_insurance' || this.clientFinancialClass == 'medicaid' || this.clientFinancialClass == 'medicare')
                    return true;
                return false;
            },
            noShowOrCancelled(){
                return this.serviceItems.some((si) => si.code === 'NO_SHOW' || si.code === 'CANCEL');
            }
        },
        methods: {
            openReasonModal(service){
                this.currentServiceToAdjust = service;
                this.showReasonModal = true;
            },
            handleReasonModalClose(action, payload){
                if(action == 'submit'){
                    this.adjustServiceFee(payload);
                }
                this.currentServiceToAdjust = null;
                this.showReasonModal = false;
            },
            async getPdf() {
                const res = await this.$api.get(invoices.getPdf(this.invoice_id));

                if (res.status < 300) {
                    await openPdf(res.data, 'Invoice');
                }
            },
            async savePdf() {
                const res = await this.$api.post(invoices.saveAsPdf(this.invoice_id));

                if (res.status < 300) {
                    this.$toasted.success("Successfully stored PDF in client's files");
                } else {
                    this.$toasted.error('Unable to save PDF at this time. Please try again later');
                }
            },
            async invoice2claim() {
                const res = await this.$api.get('/insurance/invoice2claim/' + this.invoice_id);
                let record_id = res.data.rec.claimd_id;
                if (record_id) {
                    await this.$router.push({ name: 'InsuranceClaimDetail', params: { record_id: record_id } });
                } else {
                    this.$toasted.error('Unable to Create Insurance Claim : ' + res.data.rec.errs);
                }

            },
            async goto_progress_note() {
                if (this.data.progress_note_id) {
                    await this.$router.push({ name: 'ProgressNote', params: { id: this.data.progress_note_id } });
                }
            },
            async go2client() {
                if (this.clientId) {
                    this.$router.push({ path: `/client-billing/${this.clientId}` });
                }
            },
            async loadInvoice() {
                try {
                    const res = await this.$api.get(invoices.getInvoice(this.invoice_id));

                    this.data = res.data;

                    await this.get_client_info()

                } catch (err) {
                    this.$toasted.error('Failed to load invoice - please try again later');
                }
            },
            async adjustServiceFee(serviceItem) {

                const res = await this.$api.put(serviceItems.adjustAmount(serviceItem.id), {
                    amount: serviceItem.actual_cost,
                    adjReason: serviceItem.adjReason
                });

                if (res.status < 300) {
                    await this.loadInvoice();
                    this.dirtyIndexes = [];
                    this.$toasted.success('Adjusted service item cost');
                } else {
                    this.$toasted.error('Failed to adjust service item cost');
                }
            },
            async handlePaymentModalClose() {
                await this.loadInvoice();
                this.paymentModalOpen = false;
            },
            getShortDate(isoDateString) {
                return dayjs(isoDateString).format('MM/DD/YY');
            },
            handleCurrencyChanged(newCurrencyVal, service, i) {
                service.actual_cost = this.$getNumFromCurrency(newCurrencyVal);
                this.makeIndexDirty(i);
            },
            makeIndexDirty(i) {
                if (!this.dirtyIndexes.includes(i)) {
                    this.dirtyIndexes = [...this.dirtyIndexes, i];
                }
            },
            isDirty(i) {
                return this.dirtyIndexes.includes(i);
            },
            async get_client_info() {
                try {
                    const res = await this.$api.get(`clients/${this.clientId}/general-info`);
                    this.clientFinancialClass = res.data[0].financial_class;
                } catch (err) {
                    this.$toasted.error('Could not get client info');
                }
            },
        },
        async created() {
            await this.loadInvoice();
        }
    };
</script>
