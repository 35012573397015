<template>
    <NoButtonModal :open='open' @close='cancel' id='the_new_client_dropdown_modal'>
        <button class='close-corner-x' type='button' @click.prevent='cancel'>
            <span class='material-icons-outlined dark-text'>close</span>
        </button>
        <div class='flex'>
            <div class='right-20'>
                <span class='material-icons-outlined red-text large-icon'>error_outline</span>
            </div>
            <div>
                <h2 class='bottom-5'>Warning</h2>
                <p>{{description}}</p>
            </div>

        </div>
        <ValidationObserver ref="form">
            <form @submit.prevent="submit">

                <label>
                    Adjustment Reason
                </label>
                <textarea v-model="reason" name="reason" required/>
                <div class='fullwidth align-right'>
                    <button class='secondary' type='button' @click.prevent='cancel'>Cancel</button>
                    <button class='primary' type='submit'>Proceed</button>
                </div>
            </form>
        </ValidationObserver>

    </NoButtonModal>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { ValidationObserver } from 'vee-validate';
    export default {
        name: 'RunVerificationModal',
        components: { NoButtonModal, ValidationObserver },
        props: {
            open: {
                type: Boolean,
                required: true
            },
            description: {
                default: '',
                required: false
            },
            payload: {
                default: null
            }
        },
        data() {
            return {
                reason: ""
            };
        },
        methods: {
            createEscListener(self) {
                return function(e) {
                    if (e.key === 'Escape' && self.open) {
                        this.reason = "";
                        self.$emit('close', 'cancel', null);
                    }
                };
            },
            async submit() {
                const form = this.$refs.form;
                const isValid = await form.validate();
                if (!isValid) {
                    //this.$toasted.error('Invalid fields');
                    return;
                }
                let copy = this.payload
                copy.adjReason = this.reason;
                this.resetForm('submit', copy);
            },
            cancel() {
                this.resetForm('cancel', null);
            },
            resetForm(action, payload) {
                this.reason = "";
                this.$emit('close', action, payload);
            }

        },
        async created() {},
        mounted() {},
        beforeDestroy() {
            window.removeEventListener('keydown', this.escListener);
        },

    };
</script>

<style scoped>

</style>